.feed-item {
  @include text-agenda-regular;
  padding-bottom: 10px;

  color: $darkGrey;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $lightGrey;
  flex-direction: column;
  display: flex;
  overflow: hidden;

  .comment-highlighted {
    svg {
      fill: $cauzeorange;
      path {
        stroke: $cauzeorange;
      }
    }
  }
  .feed-item-comment-container {
    display: flex;
    margin-top: 1px;

    .feed-item-comment {
      font-size: 17px;
      line-height: 120%;
      color: black;
      overflow: hidden;
      width: fit-content;
    }
    .read-more-button {
      color: #1b2031;
      text-decoration: underline;
      cursor: pointer;
      font-size: 16px;
    }
    .feed-item-actions-lines {
      margin-right: 8px;
    }

    &.no-event {
      .feed-item-comment {
        margin-top: 0px;
      }

      .feed-item-actions-lines {
        padding-top: 0px;
        border: none;
      }
    }
  }

  .feed-card-cauze-container {
    display: flex;
  }

  .feed-card-cauze-lines {
    border-left: 1px dashed $lightBorderGrey;
    padding-left: 30px;
    margin-left: 18px;
  }

  .feed-card-cauze {
    text-align: left;
    flex-grow: 1;
    margin-bottom: 0px;
    padding-bottom: 10px;

    &.has-comment {
      margin-bottom: 10px;
    }

    .feed-card-cauze-header {
      padding-top: 13px;
      padding-bottom: 13px;
      padding-left: 16px;
      padding-right: 16px;
      border-top: 1px solid rgba(148, 148, 148, 0.5);
      border-right: 1px solid rgba(148, 148, 148, 0.5);
      border-left: 1px solid rgba(148, 148, 148, 0.5);
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      @include mobile {
        display: none;
      }

      .feed-card-name {
        @include text-agenda-bold;
        font-size: 15px;
        color: $grey7;
        max-height: 29px;
        line-height: 100%;
        overflow: hidden;
        margin-bottom: 0px;

        &.has-description {
          margin-bottom: 4px;
        }
      }

      .feed-card-description {
        color: $grey7;
        font-size: 15px;
        line-height: 100%;
        max-height: 29px;
        overflow: hidden;
      }
    }

    .video-container {
      width: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      overflow: hidden;
      background-color: black;

      @include mobile {
        overflow: hidden;
      }
    }

    .event-video {
      height: 56.25vw;
      max-height: 385px;
      flex: 1;
      display: flex;
      justify-content: center;
    }

    iframe {
      width: 100vw;
      max-height: 385px;
      height: 56.25vw;
    }

    .feed-card-body {
      width: 100%;
      height: 350px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;

      @include mobile {
        height: 200px;
        border-radius: 10px;
      }

      &.no-image {
        background: transparent
          linear-gradient(195deg, #f15a29 0%, #0e1971 100%) 0% 0% no-repeat
          padding-box;
      }
    }

    .feed-card-footer {
      padding-top: 21px;
      padding-bottom: 8px;
      padding-left: 26px;
      padding-right: 26px;
      border-bottom: 1px solid rgba(148, 148, 148, 0.5);
      border-right: 1px solid rgba(148, 148, 148, 0.5);
      border-left: 1px solid rgba(148, 148, 148, 0.5);
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;

      @include mobile {
        display: none;
      }

      .avatar {
        height: 20px;
        width: 20px;
        margin-left: -4px;
        margin-right: 0px;
      }

      * {
        div {
          margin-right: 36px;
        }
      }

      .feed-card-footer-title {
        color: $textGrey;
        font-size: 15px;
        line-height: 100%;
      }

      .feed-card-raised {
        .feed-card-raised-amount {
          @include text-agenda-black;
          font-size: 20px;
          color: $textGrey;
          line-height: 100%;
        }
      }
    }
  }

  .feed-item-title {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .feed-item-actions-lines {
    border-left: 1px dashed $lightBorderGrey;
    border-bottom: 1px dashed $lightBorderGrey;
    width: 26px;
    height: 12px;
    margin-left: 18px;
    margin-right: 4px;

    &.has-comment {
      border: none;
    }
  }

  .feed-item-divider {
    height: 12px;
    margin-left: 18px;
    margin-bottom: 1px;
    margin-top: 1px;
    border-left: 1px dashed $lightBorderGrey;

    @include mobile {
      border: none;
    }
  }

  .feed-item-matches {
    display: flex;
  }

  .media-content-anchor {
    margin-top: 0.25rem;
    margin-left: 2.5rem;
    margin-bottom: 0.75rem;
    text-align: left;
    word-break: break-all;
    font-size: 13px;
  }

  .actor-verb-subject {
    line-height: 120%;
    font-size: 17px;
    height: 40px;
    display: flex;
    overflow: hidden;

    span {
      margin-top: auto;
      margin-bottom: auto;
    }

    .actor-name {
      @include text-agenda-bold;
      color: black;
      font-size: 17px;
    }
  }

  .date-container-mobile {
    margin-left: 2.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    p {
      font-size: 11px;
    }

    .status-indicator {
      height: 8px;
      width: 8px;
      margin-left: 0.75rem;
    }
  }

  // This is an override for the base text-bold util
  .text-bold {
    color: $textGrey;
  }

  .row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .feed-item-header {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      .avatar-container {
        line-height: 0;
        @include mobile {
          margin-right: 0.1rem;
        }
      }
    }

    .feed-item-footer {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;

      p {
        font-size: 11px;
      }

      .date-container {
        width: 6rem;
        color: $grey;

        .status-indicator {
          height: 8px;
          width: 8px;
          margin-left: 1rem;
          margin-right: -1.75rem;
        }
      }
    }
  }

  .avatar {
    align-self: flex-start;
    margin-right: 10px;
    height: 40px;
    width: 40px;
    border-radius: 150px;
  }

  .feed-body {
    margin-top: 0.25rem;
    margin-left: 2.5rem;
    margin-bottom: 0.75rem;
    text-align: left;
    font-size: 13px;
  }

  .feed-image-anchor {
    align-self: flex-start;
    margin: 0.5rem 1rem 0.25rem 2.6rem;
    max-width: 32.5rem;
    width: 100%;
    padding-right: 2.5rem;

    .feed-image {
      width: 100%;
      border-radius: 0.4rem;
      object-fit: cover;
    }
  }

  .feed-video {
    max-width: 30rem;
    max-height: 20rem;
    margin-left: 2.4rem;
    border-radius: 0.4rem;
    margin-top: 0.5rem;
    div {
      div {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        max-width: 30rem;
        max-height: 20rem;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          max-width: 30rem;
          max-height: 20rem;
          height: 100%;
          width: 100%;
          border-radius: 0.4rem;
        }
      }
    }
  }

  .feed-url {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    text-align: left;
    word-break: break-all;

    a {
      color: $textGrey;
    }
  }
}

.feed-item-actions {
  height: 36px;
  display: flex;
  margin-top: 1px;

  & > :not(:first-child) {
    margin-right: 14px;
  }

  .feed-item-flex {
    display: flex;
    cursor: pointer;
  }

  .feed-item-actions-lines {
    border-left: 1px dashed $lightBorderGrey;
    border-bottom: 1px dashed $lightBorderGrey;
    width: 26px;
    height: 16px;
    margin-left: 18px;
    margin-right: 4px;

    &.has-comment {
      border: none;
    }
  }

  .feed-item-icon {
    margin-top: auto;
    margin-bottom: auto;
    height: 16px;
    margin-right: 4px;
  }

  .feed-item-number {
    display: flex;

    span {
      margin-top: auto;
      margin-bottom: auto;
      height: 15px;
      font-size: 15px;
    }
  }
}
